import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../../../services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationService } from "../../../messages/notification.service";

@Injectable()
export class LoginService {
  constructor(public apiService: ApiService, public http: HttpClient, private router: Router, private notificationService: NotificationService) {}

  public currentUser: any = {};
  public backofficeUser: any = {};

  public base_url: string = "backoffice/users";

  public login(email: string, token: string, callback) {
    this.loadCurrentUser();
    this.apiService.post(`${this.base_url}/sign_in`, { email: email, token: token }).subscribe((data: any) => {
      this.processResponse(data, callback);
      if (this.currentUser.password_expired) {
        this.router.navigateByUrl("backoffice/account/change_password");
      }
    });
  }

  public token(email: string, password: string, callback) {
    this.loadCurrentUser();
    this.apiService.post(`backoffice/send_token`, { email: email, password: password }).subscribe((data: any) => {
      this.processResponse(data, callback);
    });
  }

  public refreshUserRoles(callback = null) {
    this.apiService.get(`${this.base_url}/${this.currentUser.id}/roles`).subscribe((data: any) => {
      this.currentUser.roles = data;
      localStorage.setItem("backofficeUser", JSON.stringify(this.currentUser));
      if (callback) callback(this.currentUser);
    });
  }

  private processResponse(data, callback) {
    if (data.token) {
      this.currentUser = data;
      this.currentUser.loginType = "backoffice";
      localStorage.setItem("backofficeUser", JSON.stringify(this.currentUser));
      callback(this.currentUser);
    } else {
      callback(data);
    }
  }

  hasRole(role) {
    const user_roles = [];
    for (let i = 0; i < this.currentUser.roles.length; i++) user_roles.push(Object.keys(this.currentUser.roles[i])[0]);
    return (
      user_roles.filter((elem) => {
        return elem == role;
      }).length > 0
    );
  }

  public getCurrentUser(for_token = false) {
    if (this.isLogged()) {
      this.loadCurrentUser();
      return this.currentUser;
    }
    return false;
  }

  public isLogged() {
    if (this.currentUser && Object.keys(this.currentUser).length !== 0) return true;
    if (this.currentUser) {
      this.loadCurrentUser();
      return true;
    }

    return false;
  }

  public loadCurrentUser() {
    this.currentUser = JSON.parse(localStorage.getItem("backofficeUser"));
  }

  public getToken() {
    if (this.isLogged()) return this.currentUser.token;
    return "";
  }

  public logout() {
    if (this.currentUser) {
      let loginType = this.currentUser.loginType;
      this.currentUser = null;
      this.currentUser = localStorage.removeItem("backofficeUser");
      this.handleLogin(loginType);
    }
  }

  public handleLogin(path?: string) {
    this.router.navigate([`/backoffice/login`, { to: btoa(path || "/") }]);
  }
}
