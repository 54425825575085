import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { User } from "../../../../models/user";
import { Router, ActivatedRoute } from "@angular/router";
import { NotificationService } from "../../../messages/notification.service";
import { LoginService } from "./login.service";
import { AppComponent } from "../../../../app.component";

@Component({
  selector: "app-auth-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public user: { email: string; password: string; token: string };
  public backofficeLogin: boolean;
  public navigateTo: string;
  public submitText: string;
  public step: string;
  public remember: boolean;
  public forgotUrl: string;
  public homeRoute: string;
  public is_logged: boolean;
  public currentUser: User = new User();
  public resend_token: boolean;
  public count: number;
  @ViewChild("passwordInput") passwordInput: ElementRef;
  @ViewChild("emailInput") emailInput: ElementRef;
  @ViewChild("tokenInput") tokenInput: ElementRef;

  constructor(public appComponent: AppComponent, public activatedRoute: ActivatedRoute, public loginService: LoginService, public router: Router, public notificationService: NotificationService) {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("backofficeUser"));
    this.is_logged = this.currentUser ? true : false;
    if (this.is_logged) {
      this.router.navigateByUrl("backoffice/dashboard");
    }

    if (this.is_logged && this.currentUser.password_expired) {
      this.router.navigateByUrl("backoffice/account/change_password");
    }

    this.appComponent.load(false);
    this.backofficeLogin = this.router.url.includes("backoffice");

    this.submitText = "Próximo";

    this.remember = false;

    this.step = "email";

    if (this.backofficeLogin) {
      this.user = new User();
      this.homeRoute = "/backoffice";
      this.forgotUrl = "/backoffice/forgot_password";
    }

    setTimeout(() => this.emailInput && this.emailInput.nativeElement.focus(), 0);
    this.navigateTo = this.activatedRoute.snapshot.params["to"] || btoa(this.homeRoute);
  }

  onSubmit() {
    this.step === "email" ? this.getToken() : this.doLogin();
  }

  getToken() {
    this.resend_token = false;
    this.count = 60;

    this.appComponent.load(true);
    this.loginService.token(this.user.email, this.user.password, (data) => {
      if (data.error) {
        this.appComponent.load(false);
        this.notificationService.notify(data.error, "danger");
        return;
      } else {
        setTimeout(() => this.tokenInput.nativeElement.focus(), 0);
        this.step = "token";
        this.submitText = "Entrar";
      }
      this.appComponent.load(false);
      this.notificationService.notify("Token de autenticação foi enviado para o seu e-mail. Por favor, verifique sua caixa de entrada e insira o código para prosseguir.", "dark");
    });
    const interval = setInterval(() => {
      this.count--;

      if (this.count <= 0) {
        clearInterval(interval);
        this.resend_token = true;
      }
    }, 1000);
  }

  doLogin() {
    this.appComponent.load(true);
    this.loginService.login(this.user.email, this.user.token, (data) => {
      if (!data.error) {
        this.notificationService.notify(`Usuário ${data.first_name} autenticado com sucesso.`, "success");
        let to_path = atob(this.navigateTo);
        to_path = to_path == "/" ? `/backoffice` : to_path;
        if (to_path.includes("login")) to_path = this.homeRoute;
        this.router.navigate([to_path]);
      } else {
        this.notificationService.notify(data.error, "danger");
        this.toEnterEmail();
      }
      this.appComponent.load(false);
    });
  }

  toEnterEmail() {
    this.user.email = "";
    this.user.password = "";
    this.step = "email";
    this.submitText = "Próximo";
    setTimeout(() => this.emailInput.nativeElement.focus(), 0);
  }

  public forgot() {
    this.router.navigateByUrl(this.forgotUrl);
  }
}
