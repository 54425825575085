export const sfs_assign_map = [
  // CHECKLIST: Arquivos para verificar sempre que houver alteração em algum campo do relatório:
  // 1) post.rb ===> adicionar/remover o atributo/método referentes aos campos novos. Obs.: Um atributo booleano vai precisar de um método para formatar a informação ("Sim" ou "Não").
  // 3) post_serializer.rb ===> adicionar/remover o atributo/método novos lá.
  // 4) _posts_shipped_list.json.jbuilder ===> adicionar/remover lá apenas os MÉTODOS de post. Adicionar na arrray da linha 2 e no bloco de métodos só de SFS.
  // 5) BR.models.yml ==> adicionar/remover o atributo/método novos. São os cabeçalhos que aparecem na planilha.
  // 6) ship_from_store_assign_map.ts ==> Adicionar abaixo o atributo/método e o label (do modal do formulário do browser).
  // 7) customer_report.component.ts ==> É para o relatório de admin. Adicionar o atributo/método na const fields do Submit.
  {
    title: "Dados Principais",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "customer_identifier", label: "Marca", required: false },
        { attribute: "brand_name_sfs", label: "Loja", required: false },
        { attribute: "brand_cnpj", label: "CNPJ da Loja", required: false },
        { attribute: "billing_sfs", label: "Origem da venda", required: false },
        { attribute: "original_history", label: "Gerado via", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "client_document_number", label: "Número do cliente", required: false },
        { attribute: "last_tracking_status_post_status_name", label: "Status do Pedido", required: true },
        { attribute: "complementary_parent_post", label: "Número Pedido principal", required: false },
        { attribute: "complementary_show_report", label: "Pedido Complementar?", required: false },
        { attribute: "complementary_index", label: "Ordem Complementar/Total Complementares", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "description", label: "Descrição", required: false },
        { attribute: "invoice_value", label: "Valor da Nota Fiscal", required: false },
        { attribute: "last_carrier_tracking_status_collected_formated", label: "Data/Hora Coleta", required: false },
        { attribute: "delivery_date_time_formated", label: "Data/Hora da Entrega", required: false },
        { attribute: "attempts_quantity", label: "Quantidade de tentativas", required: false },
      ],
    ],
  },
  {
    title: "Dados do Roteiro",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "created_at_formated_sfs", label: "Data/Hora criação Pedido", required: false },
        { attribute: "post_status_name", label: "Status do roteiro", required: false },
        { attribute: "levoo_route_delivery_fee", label: "Valor do frete", required: false },
        { attribute: "distance_from_origin_serialized", label: "Quilometragem", required: false },
        { attribute: "levoo_route_created_at_formated", label: "Data/Hora criação roteiro", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "levoo_route_deliverer_name", label: "Nome do entregador", required: false },
        { attribute: "levoo_route_levoo_identifier", label: "Código do roteiro", required: false },
        { attribute: "franchise_route_social_name", label: "Franquia do Atendimento", required: false },
        { attribute: "find_franchise_fantasy_name_by_brand", label: "Franquia Padrão", required: false },
        { attribute: "courier_employment_type", label: "Contrato do Entregador", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "franchise_route_identifier", label: "Franquia do Atendimento - Identificador", required: false },
        { attribute: "levoo_route_deliverer_vehicle_type", label: "Veículo", required: false },
        { attribute: "carrier", label: "Transportadora", required: false },
        // { 'attribute': 'levoo_route_contract_window', 'label': 'Contrato janela postagem', 'required': false }, // A ser desenvolvido
        // { 'attribute': 'levoo_route_delivery_fee_client', 'label': 'Valor do frete Cliente', 'required': false }, // A ser desenvolvido
        // { 'attribute': 'levoo_route_delivery_fee_franchise', 'label': 'Valor do frete Franquia', 'required': false }, // A ser desenvolvido
        // { 'attribute': 'levoo_route_delivery_fee_courier', 'label': 'Valor do frete Courier', 'required': false } // A ser desenvolvido
      ],
    ],
  },
  {
    title: "Dados do Destinatário",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "recipient_name", label: "Nome do destinatário", required: false },
        { attribute: "recipient_address", label: "Logradouro do destinatário", required: false },
        { attribute: "recipient_address_number", label: "Número do destinatário", required: false },
        { attribute: "recipient_address_complement", label: "Complemento do destinatário", required: false },
        { attribute: "recipient_address_neighborhood", label: "Bairro do destinatário", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "recipient_address_city", label: "Cidade do destinatário", required: false },
        { attribute: "recipient_address_state", label: "UF do destinatário", required: false },
        { attribute: "recipient_address_zipcode", label: "CEP do destinatário", required: false },
        { attribute: "recipient_address_reference_point", label: "Ponto de referência do destinatário", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "recipient_phone_1", label: "Telefone 1 do destinatário", required: false },
        { attribute: "recipient_phone_2", label: "Telefone 2 do destinatário", required: false },
        { attribute: "recipient_email", label: "E-mail do destinatário", required: false },
        { attribute: "recipient_department", label: "Departamento do destinatário", required: false },
      ],
    ],
  },
  {
    title: "Dados do Recebedor",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "receiver_name", label: "Nome do recebedor", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "receiver_document", label: "Documento do recebedor", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "receiver_role", label: "Função do recebedor", required: false },
      ],
    ],
  },
  {
    title: "Dados de Retirada",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "cd_address", label: "Logradouro de retirada", required: false },
        { attribute: "cd_address_number", label: "Número de retirada", required: false },
        { attribute: "cd_address_complement", label: "Complemento de retirada", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "cd_address_neighborhood", label: "Bairro de retirada", required: false },
        { attribute: "cd_address_city", label: "Cidade de retirada", required: false },
        { attribute: "cd_address_state", label: "UF de retirada", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "cd_address_zipcode", label: "CEP de retirada", required: false },
        { attribute: "origin_contact_phone", label: "Telefone de retirada", required: false },
        { attribute: "brand_identifier", label: "Contato de retirada", required: false },
      ],
    ],
  },
  {
    title: "Dados de Embalagem",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "package_weight_gr", label: "Peso da encomenda (em gr) *", required: true },
        { attribute: "package_width_cm", label: "Largura da embalagem (em cm)", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "package_length_cm", label: "Comprimento da embalagem (em cm)", required: false },
        { attribute: "package_height_cm", label: "Altura da embalagem (em cm)", required: false },
      ],
    ],
  },
  {
    title: "Dados de Ocorrência",
    fields: [
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "failure_reason", label: "Motivo insucesso", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "first_send_date", label: "Primeira tentativa de coleta", required: false },
      ],
      [
        // ANTES DE ALTERAR, LER CHECKLIST ACIMA!
        { attribute: "elapsed_time_on_operation", label: "Tempo de execução", required: false },
      ],
    ],
  },
];
