<div class="be-wrapper be-login">
  <div class="be-content login-container d-flex">
    <div class="main-content container-fluid d-flex login-box">
      <section class="vector-container">
        <div class="vector-box">
          <img src="assets/img/vector.png" alt="logo" class="vector" />
        </div>
      </section>
      <section>
        <div class="splash-container">
          <div class="card form-box">
            <div class="card-header"><img src="assets/img/logo_focco_large_dark@2x.png" alt="logo" width="139" class="logo-img" /><span class="splash-description">Preencha os dados abaixo para entrar:</span></div>
            <div class="card-body">
              <div *ngIf="this.is_logged">
                <div class="d-flex align-items-center">
                  <p class="mr-2">
                    <i class="fa fa-check-circle text-success"></i>
                  </p>
                  <p>Usuário já autenticado: {{ this.currentUser.first_name }} {{ this.currentUser.last_name }}</p>
                </div>
                <span>... Redirecionando</span>
              </div>
              <form *ngIf="!this.is_logged" (ngSubmit)="onSubmit()" novalidate>
                <div class="form-group">
                  <input type="email" class="form-control" *ngIf="step == 'email'" placeholder="E-mail" [(ngModel)]="this.user.email" name="userEmail" #emailInput />
                  <input type="password" class="form-control mt-3" *ngIf="step == 'email'" placeholder="Senha" [(ngModel)]="this.user.password" name="userPassword" #passwordInput />
                  <div *ngIf="step == 'token'">
                    <label>Digite abaixo o token recebido por e-mail</label>
                    <input type="token" class="form-control" placeholder="Token" [(ngModel)]="this.user.token" name="userToken" #tokenInput />
                    <button type="button" class="btn btn-link btn-xl" [disabled]="!resend_token" (click)="getToken()">Reenviar token {{ this.count > 0 ? this.count : "" }}</button>
                  </div>
                </div>
                <div class="form-group row login-tools">
                  <div class="col-6 login-remember">
                    <label *ngIf="step == 'email'" class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" [(ngModel)]="this.remember" name="userRemember" /><span class="custom-control-label">Lembrar de mim</span>
                    </label>
                  </div>
                  <div *ngIf="step == 'email'" class="col-6 login-forgot-password"><a (click)="forgot()">Esqueceu sua senha?</a></div>
                </div>
                <div class="form-group login-submit text-center">
                  <button data-dismiss="modal" type="submit" class="btn btn-primary btn-xl mb-2">{{ submitText }}</button>
                  <a *ngIf="step == 'token'" (click)="this.toEnterEmail()">Voltar</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
